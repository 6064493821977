import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from "axios"
Vue.prototype.$axios = axios

import axiosAdmin from './axios/admin'
Vue.prototype.$axiosAdmin = axiosAdmin

import qs from 'qs'
Vue.prototype.$qs = qs

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import '../src/assets/iconfont/iconfont.css';

import VueCropper from 'vue-cropper';
Vue.use(VueCropper);


Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
